<template>
  <div>
    <!-- Inicio drag and drop -->
    <draggable v-model="custom_fields" class="cursor-move" tag="div" @update="reorder">
      <b-row v-for="(custom, index) in custom_fields" :key="index">
        <b-col>
          <b-card>
            <div v-if="custom.front_name === 'Exclusividad a solicitar'">
              <h3 class="mb-0 blueC">{{ custom.front_name }}</h3>
              <b-form-checkbox
                v-model="exclusiviteCheck"
                class="custom-control-primary text-left mt-1 mb-1"
                @change="changeExclusivite(custom)"
              >
                  {{$t('campaigns.showMessage')}}
              </b-form-checkbox>
              <b-form-radio-group
                v-model="exclusibility"
                :options="optionsExclusibility"
                class="demo-inline-spacing mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
                @change="changeExclusiviteValue(custom)"
              />
            </div>
            <div v-else-if="custom.front_name === 'Medios donde se utilizará al influencer'">
              <h3 class="mb-0 blueC">{{ custom.front_name }}</h3>
              <b-form-checkbox
                v-model="influencerMedios"
                class="custom-control-primary text-left mt-1 mb-1"
                @change="changeMedios(custom)"
              >
                {{$t('campaigns.showMessage')}}
              </b-form-checkbox>
              <b-form-radio-group
                v-model="medios"
                :options="optionsMedios"
                class="demo-inline-spacing mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
                @change="changeMediosValue(custom)"
              />
            </div>
            <div v-else>
              <div class="d-flex align-items-center">
                <h3 class="mb-0 blueC">{{ custom.front_name }}</h3>
                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click="openModalEdit(custom)"
                  :disabled="disabled_update"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="flat-danger"
                  class="btn-icon"
                  @click="deleteCustom(custom.uuid)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <div class="plusGrand" v-html="custom.form_value"></div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </draggable>
    <!-- Fin drag and drop -->

    <b-row class="text-center">
      <b-col>
        <b-button variant="success" :disabled="disabled_update" @click="showModalAddCustom()"> {{$t('campaigns.addRequirement')}}</b-button>
      </b-col>
    </b-row>

    <modal-brandme size="xl" :show_modal="show_modal" :key="change_modal">
      <template #title>{{$t('campaigns.newRequirement')}}</template>
      <template #body>
        <new-requirement type_custom_field="custom_field" :editable_data="editable_custom" :campaign_uuid="$route.params.uuid" :order="custom_fields.length" @closeModal="closeModalCustomField" @customCreated="customCreated"></new-requirement>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BFormRadioGroup,
  BButton
} from "bootstrap-vue";
import ModalBrandme from "@/views/components/modal/ModalBrandme.vue";
import newRequirement from "../newRequirement.vue";
import draggable from 'vuedraggable'
import service_campaign from "@/services/campaign";

export default {
  name: 'customFields',
  components: {
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BFormRadioGroup,
    BButton,
    ModalBrandme,
    newRequirement,
    draggable
  },
  props: {
    campaign: {
      type: Object,
      default: () => {}
    },
    disabled_update: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      custom_fields: [],
      exclusiviteCheck: false,
      exclusibility: 'Solo mi categoría',
      optionsExclusibility: [
        { text: 'Solo mi categoría', value: 'Solo mi categoría', disabled: false },
        { text: 'Todo NARD', value: 'Todo NARD', disabled: false },
        { html: 'NARD y Bebidas Baja Graduación', value: 'NARD y Bebidas Baja Graduación', disabled: false },
        { text: 'NARD, Baja Graduación y Alcoholicas', value: 'NARD, Baja Graduación y Alcoholicas', disabled: false }
      ],
      optionsMedios: [
        { text: 'Full Media', value: 'Full Media', disabled: false },
        { text: 'Solo digital', value: 'Solo digital', disabled: false }
      ],
      influencerMedios: true,
      show_modal: false,
      change_modal: false,
      editable_custom: null
    }
  },
  created() {
    const custom_fields = this.campaign.custom_fields.filter(item => (item.network === null || item.network === '') && (item.type_field === '' || item.type_field === null));
    this.custom_fields = this.orderCustomFields(custom_fields)
  },
  methods: {
    orderCustomFields(custom_fields) {
      custom_fields.sort(function(a, b) {
        return a.order - b.order;
      });
      custom_fields.forEach(custom => {
        if (custom.front_name === 'Exclusividad a solicitar') {
          this.exclusibility = custom.form_value;
          this.exclusiviteCheck = custom.show_link;
        }

        if (custom.front_name === 'Medios donde se utilizará al influencer') {
          this.medios = custom.form_value;
          this.influencerMedios = custom.show_link;
        }
      })

      return custom_fields
    },
    showModalAddCustom() {
      this.editable_custom = null
      this.show_modal = true
      this.change_modal = !this.change_modal
    },
    customCreated(new_custom_fields) {
      const custom_fields = new_custom_fields.filter(item => (item.network === null || item.network === '') && (item.type_field === '' || item.type_field === null));
      this.custom_fields = this.orderCustomFields(custom_fields)
      this.closeModalCustomField()
    },
    reorder() {
      const new_order = []
      this.custom_fields.forEach((custom, index) => {
        const obj = {
          'uuid': custom.uuid,
          'order': index
        }
        new_order.push(obj)
      });
    
      service_campaign.reorderCustomFields(this.$route.params.uuid, new_order)
        .then(() => {
        })
    },
    changeExclusivite(custom) {
      this.updateFieldsPlusCustomupdateFields(
        custom.uuid,
        custom.front_name,
        this.exclusiviteCheck,
        custom.form_value,
        custom.order,
        custom.order
      );
    },
    changeExclusiviteValue(custom) {
      this.updateFieldsPlusCustomupdateFields(
        custom.uuid,
        custom.front_name,
        custom.show_link,
        this.exclusibility,
        custom.order,
        custom.order
      );
    },
    changeMedios(custom) {
      this.updateFieldsPlusCustomupdateFields(
        custom.uuid,
        custom.front_name,
        this.influencerMedios,
        custom.form_value,
        custom.order,
        custom.order
      );
    },
    changeMediosValue(custom) {
      this.updateFieldsPlusCustomupdateFields(
        custom.uuid,
        custom.front_name,
        custom.show_link,
        this.medios,
        custom.order,
        custom.order
      );
    },
    updateFieldsPlusCustomupdateFields (uuid, front_name, show_link, form_value, index, order) {
      const formData = new FormData()
      formData.append('form_value', form_value)
      formData.append('front_name', front_name)
      formData.append('show_link', show_link)
      formData.append('order', order)
      this.updateField(this.$route.params.uuid, uuid, formData)
    },
    openModalEdit(custom) {
      this.editable_custom = custom
      this.show_modal = true
      this.change_modal = !this.change_modal
    },
    closeModalCustomField() {
      this.show_modal = false
      this.change_modal = !this.change_modal
      this.editable_custom = null
    },
    updateField (campaign_uuid, field_uuid, formdata) {
      service_campaign.updateCampaignField(campaign_uuid, field_uuid, formdata)
        .then(() => {
          this.$swal({
            title: this.$t('campaigns.modifiedField'),
            text: this.$t('campaigns.fieldSuccess'),
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
          this.$emit('rerender')
        })
        .catch(() => {
        })
    },
    deleteCustom (custom_uuid) {
      this.$swal({
        title: this.$t('campaigns.textConfirmRequest'),
        text: this.$t('campaigns.textRemoveRequest'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn confirm-button ',
          cancelButton: 'btn btn-outline-danger mr-1'
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmRequest'),
        reverseButtons: true,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          service_campaign.deleteCustomField(this.$route.params.uuid, custom_uuid)
            .then(response => {
              if (response.status) {
                this.$swal({
                  title:  this.$t('campaigns.Error'),
                  text: this.$t('campaigns.requirementError'),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false
                })
              } else {
                this.custom_fields = this.custom_fields.filter(function(custom) {
                  return custom.uuid !== custom_uuid
                })
              }
            })
            .catch(() => {
              this.$swal({
                title: this.$t('campaigns.Error'),
                text: this.$t('campaigns.requirementError'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false
              })
            })
        }
      })
    },
  }
};
</script>

<style>
</style>
